<template>
    <report-component default-error-message="No reports to show" />
</template>
<script>

import ReportComponent from '~/components/Report.vue';

export default {
    name: 'ReportEmpty',
    components: { ReportComponent },
}
</script>
<style lang="scss">
@import '~/styles/components/_power-bi.scss';
</style>
